export const ApplicationRoutes = {
  Home: '',
  Dashboard: 'dashboard',
  Signup: 'register',
  Login: 'login',
  Page404: '404',
  Profile: 'profile',
  Projects: 'projects',
  Candidates: 'candidates',
  Tools: 'tools',
  Sourcing: 'sourcing',
  Career: 'career-pages',
  AutoSourcing: 'auto-sourcing'
}
