import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { environment } from '@upkey-platform/core/core-shared'
import * as Sentry from '@sentry/angular'
import { Integrations } from '@sentry/tracing'
import { AppBrowserModule } from './app/app.browser.module'
import packageInfo from '../../../package.json'

if (environment.production) {
  Sentry.init({
    release: packageInfo.version,
    environment: environment.sentry.environment,
    dsn: environment.sentry.dsn,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost'],
        routingInstrumentation: Sentry.routingInstrumentation
      })
    ],
    tracesSampleRate: 1.0,
    ignoreErrors: [
      // Ignored regarding https://github.com/getsentry/sentry-javascript/issues/2292
      'Non-Error exception captured',
      'Non-Error exception captured with keys: error, headers, message, name, ok',

      // Ignore errors when user block camera/audio or some other permissions that needed
      'Non-Error promise rejection captured',

      // This error only happened on older versions of Chrome Mobile Browser and it is a browser specific error
      'ResizeObserver loop limit exceeded',

      // Ignore Vivo (device family) specific errors
      'privateSpecialRepair is not defined',

      // Ignore issues related to the HeyTap Browser
      'getReadModeConfig',
      'getReadModeExtract',
      'getReadModeRender'
    ]
  })

  enableProdMode()
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppBrowserModule)
    .catch((err) => console.error(err))
})
