import { Injectable, Inject, NgZone } from '@angular/core'
import { Router } from '@angular/router'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { ICustomerUser } from '@upkey-platform/accounts/customer-user-shared'
import { TrackService } from '@upkey-platform/core/core-frontend'
import { throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { CustomerUserService } from '../customer-user.service'
import {
  GetCurrentUser,
  LoginCustomerUser,
  Logout,
  GetPaymentLink,
  RegisterCustomerUser,
  VerifyEmail,
  VerifyLogin,
  VerifyPayment,
  MakePayment
} from './customer-user.actions'
import { CustomerUserStateModel } from './customer-user.model'
import { IS_PLATFORM_SERVER } from '@upkey-platform/core/core-data-access'

@State<CustomerUserStateModel>({
  name: 'CustomerUser'
})
@Injectable()
export class CustomerUserState {
  constructor(
    private customerUserervice: CustomerUserService,
    private trackService: TrackService,
    private ngZone: NgZone,
    private router: Router,
    @Inject(IS_PLATFORM_SERVER) public isServer: boolean
  ) {}

  @Selector()
  static currentUser(state: CustomerUserStateModel) {
    return state.user
  }

  @Selector()
  static currentUserName(state: CustomerUserStateModel) {
    return state.user ? `${state.user.firstName} ${state.user.lastName}` : ''
  }

  @Selector()
  static companyUserName(state: CustomerUserStateModel) {
    return state.user?.customer?.name
  }

  @Action(RegisterCustomerUser)
  registerCustomerUser(ctx: StateContext<CustomerUserStateModel>, { payload }: RegisterCustomerUser) {
    return this.customerUserervice.registerUser(payload).pipe(
      tap((user: ICustomerUser) => {
        this.trackService.mixpanel({
          fn: 'track',
          info: {
            eventName: 'Ai Sign up'
          }
        })
        this.trackService.mixpanel({
          fn: 'increment',
          info: {
            properties: {
              '# of logins': 1
            }
          }
        })
        this.trackService.mixpanel({
          fn: 'set_once',
          info: {
            properties: {
              'First login': new Date().toUTCString(),
              'Reg. date': new Date().toUTCString()
            }
          }
        })
        this.trackService.mixpanel({
          fn: 'set',
          info: {
            properties: {
              'Last login': new Date().toUTCString()
            }
          }
        })

        ctx.patchState({
          user
        })
      }),
      catchError((err) => {
        return throwError(() => err)
      })
    )
  }

  @Action(GetCurrentUser)
  setCurrentUser(ctx: StateContext<CustomerUserStateModel>) {
    return this.customerUserervice.getCurrentUser().pipe(
      tap((user: ICustomerUser) => {
        this.trackService.mixpanel({
          fn: 'identify',
          info: {
            identifyId: `Ai UserID-${user.id}`
          }
        })
        this.trackService.mixpanel({
          fn: 'increment',
          info: {
            properties: {
              '# of logins': 1
            }
          }
        })
        if (!this.isServer) {
          this.trackService.mixpanel({
            fn: 'set',
            info: {
              properties: {
                'Last login': new Date().toUTCString()
              }
            }
          })
        }
        ctx.patchState({ user })
      })
    )
  }

  @Action(VerifyEmail)
  verifyEmail(ctx: StateContext<CustomerUserStateModel>, { payload }: VerifyEmail) {
    return this.customerUserervice.verifyEmail(payload).pipe(
      tap((user: ICustomerUser) => {
        if (user) {
          this.trackService.mixpanel({
            fn: 'track',
            info: {
              eventName: 'Ai Sign up - verified'
            }
          })

          this.trackService.googleAnalytics({
            category: 'Account verfied',
            action: 'email confirm'
          })
        }
        ctx.patchState({ user })
      })
    )
  }

  @Action(LoginCustomerUser)
  loginCustomerUser(ctx: StateContext<CustomerUserStateModel>, { payload }: LoginCustomerUser) {
    return this.customerUserervice.login(payload)
  }

  @Action(VerifyLogin)
  verifyLogin(ctx: StateContext<CustomerUserStateModel>, { payload }: VerifyLogin) {
    return this.customerUserervice.verifyLogin(payload).pipe(
      tap((user: ICustomerUser) => {
        if (user) {
          this.trackService.mixpanel({
            fn: 'track',
            info: {
              eventName: 'Ai Sign up - verified'
            }
          })

          this.trackService.googleAnalytics({
            category: 'Account verfied',
            action: 'email confirm'
          })
        }
        ctx.patchState({ user })
      })
    )
  }

  @Action(Logout)
  logout(ctx: StateContext<CustomerUserStateModel>) {
    return this.customerUserervice.logout().pipe(
      tap(() => {
        this.trackService.mixpanel({
          fn: 'reset'
        })
        ctx.patchState({
          user: null
        })
        this.ngZone.run(() => this.router.navigateByUrl('/login'))
      })
    )
  }

  @Action(MakePayment)
  makePayment(ctx: StateContext<CustomerUserStateModel>, { payload }: MakePayment) {
    return this.customerUserervice.makePayment(payload).pipe(
      tap((response) => {
        window.open(response.url, '_self')
      })
    )
  }

  @Action(GetPaymentLink)
  getPaymentLink(ctx: StateContext<CustomerUserStateModel>, { paymentId }: GetPaymentLink) {
    return this.customerUserervice.getPaymentLink(paymentId).pipe(
      tap((response) => {
        window.open(response.url, '_self')
      })
    )
  }

  @Action(VerifyPayment)
  verifyPayment(ctx: StateContext<CustomerUserStateModel>, { paymentId }: VerifyPayment) {
    return this.customerUserervice.verifyPayment(paymentId)
  }
}
