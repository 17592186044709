import { IEmailVerificationRequest } from '@upkey-platform/accounts/user-shared'
import { ICreatePayWithPaymentLink, RegisterCustomerUserPayload } from '../customer-user.interface'
export class RegisterCustomerUser {
  static readonly type = '[CustomerUser] Register Customer User'
  constructor(public payload: RegisterCustomerUserPayload) {}
}

export class VerifyEmail {
  static readonly type = '[CustomerUser] Verify Email'
  constructor(public payload: IEmailVerificationRequest) {}
}

export class GetCurrentUser {
  static readonly type = '[CustomerUser] Get Current User'
}

export class LoginCustomerUser {
  static readonly type = '[CustomerUser] Login Customer User'
  constructor(public payload: string) {}
}

export class VerifyLogin {
  static readonly type = '[CustomerUser] Verify Login'
  constructor(public payload: IEmailVerificationRequest) {}
}

export class Logout {
  static readonly type = '[CustomerUser] Logout'
}

export class MakePayment {
  static readonly type = '[CustomerUser] Make Payment'
  constructor(public payload: ICreatePayWithPaymentLink) {}
}

export class GetPaymentLink {
  static readonly type = '[CustomerUser] Get Payment Link'
  constructor(public paymentId: string) {}
}

export class VerifyPayment {
  static readonly type = '[CustomerUser] Verify Payment'
  constructor(public paymentId: string) {}
}
