import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router'
import { Select } from '@ngxs/store'
import { ICustomerUser } from '@upkey-platform/accounts/customer-user-shared'
import { map, Observable, take } from 'rxjs'
import { CustomerUserState } from '@upkey-platform/accounts/customer-user/customer-user-data-access'
import { ApplicationRoutes } from '../../app-routes-names'
@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {
  @Select(CustomerUserState.currentUser) currentUser$: Observable<ICustomerUser>

  constructor(private router: Router) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.currentUser$.pipe(
      take(1),
      map((user) => {
        const canActivate = !!user

        if (!canActivate) {
          this.router.navigate([ApplicationRoutes.Login])
        }

        return canActivate
      })
    )
  }
}
