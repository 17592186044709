import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ApplicationRoutes } from './app-routes-names'
import { CombinedGuard } from './core/guards/combined.guard'
import { CurrentUserGuard } from './core/guards/current-user.guard'
import { LoggedInGuard } from './core/guards/logged-in.guard'

const appRoutes: Routes = [
  {
    path: '',
    canActivate: [CurrentUserGuard],
    children: [
      {
        path: ApplicationRoutes.Home,
        loadChildren: () => import('./features/public/public.module').then((m) => m.PublicModule)
      },
      {
        path: '',
        loadChildren: () => import('./features/auth/auth.module').then((m) => m.AuthModule)
      },
      {
        path: ApplicationRoutes.Page404,
        loadChildren: () => import('./features/page-404/page-404.module').then((m) => m.Page404Module)
      }
    ]
  },
  {
    path: '',
    canActivate: [CombinedGuard],
    data: {
      guards: [CurrentUserGuard, LoggedInGuard]
    },
    children: [
      {
        path: ApplicationRoutes.AutoSourcing,
        loadChildren: () =>
          import('./features/auto-sourcing/auto-sourcing.module').then((m) => m.AutoSourcingModule)
      },
      { path: ApplicationRoutes.Dashboard, redirectTo: ApplicationRoutes.AutoSourcing },
      {
        path: ApplicationRoutes.Dashboard,
        // redirectTo: ApplicationRoutes.AutoSourcing
        loadChildren: () =>
          import('./features/dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: ApplicationRoutes.Tools,
        redirectTo: ApplicationRoutes.AutoSourcing
        // loadChildren: () => import('./features/tools/tools.module').then((m) => m.ToolsModule)
      },
      {
        path: ApplicationRoutes.Projects,
        loadChildren: () => import('./features/projects/projects.module').then((m) => m.ProjectsModule)
      },
      {
        path: ApplicationRoutes.Candidates,
        redirectTo: ApplicationRoutes.AutoSourcing
        // loadChildren: () =>
        //   import('./features/candidates/candidates.module').then((m) => m.CandidatesModule)
      }
    ]
  },
  { path: '**', redirectTo: ApplicationRoutes.Page404 }
]

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(appRoutes, {
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
