import { Injector, NgModule } from '@angular/core'
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics'
import { environment } from '@upkey-platform/core/core-shared'
import { AppComponent } from './app.component'
import { AppModule } from './app.module'
import { AppInjector } from '@upkey-platform/core/core-frontend'

@NgModule({
  imports: [AppModule, NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsTokenAi)],
  bootstrap: [AppComponent]
})
export class AppBrowserModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector)
  }
}
