import { Component, Inject, OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { GoogleAnalyticsService } from 'ngx-google-analytics'
import { filter, map } from 'rxjs'
import { TrackService } from '@upkey-platform/core/core-frontend'
import { IS_PLATFORM_SERVER } from '@upkey-platform/core/core-data-access'
@Component({
  selector: 'u-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ai'

  constructor(
    private router: Router,
    private trackService: TrackService,
    private gaService: GoogleAnalyticsService,
    private titleService: Title,

    @Inject(IS_PLATFORM_SERVER) public isServer: boolean
  ) {}

  ngOnInit(): void {
    if (!this.isServer) {
      this.router.events
        .pipe(
          filter((e) => e instanceof NavigationEnd),
          map(() => {
            let route: ActivatedRoute = this.router.routerState.root
            let routeTitle = ''
            while (route?.firstChild) {
              route = route.firstChild
            }
            if (route.snapshot.data['title']) {
              routeTitle = route?.snapshot.data['title']
            }
            return routeTitle
          })
        )
        .subscribe((title: string) => {
          if (title) {
            this.titleService.setTitle(title)
          }
          this.gaService.pageView(window.location.href, window.document.title)
          this.trackService.mixpanel({
            fn: 'register',
            info: {
              properties: {
                'Page url': window.location.href
              }
            }
          })
          this.trackService.mixpanel({
            fn: 'track',
            info: {
              eventName: 'Page view'
            }
          })
        })
    }
  }
}
