import { environment } from '@upkey-platform/core/core-shared'

export const CUSTOMER_USER_API_URL = {
  register: `${environment.baseApiUrl}/ai/cb/auth/register`,
  login: `${environment.baseApiUrl}/ai/cb/auth/login`,
  verifyEmail: `${environment.baseApiUrl}/ai/cb/auth/email/verify`,
  verifyLogin: `${environment.baseApiUrl}/ai/cb/auth/login/verify`,
  me: `${environment.aiApiUrl}/ai/cb/auth/whoami`,
  logout: `${environment.aiApiUrl}/ai/cb/auth/logout`,
  paymentLink: `${environment.aiApiUrl}/ai/payments/payment-links`,
  verifyPayment: `${environment.aiApiUrl}/ai/payments/verify-checkout`
}
