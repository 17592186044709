import { Injectable } from '@angular/core'
import { ICustomerUser } from '@upkey-platform/accounts/customer-user-shared'
import { HttpService } from '@upkey-platform/core/core-data-access'
import { Observable } from 'rxjs'
import { CUSTOMER_USER_API_URL } from './customer-user-api-routes'
import { ICreatePayWithPaymentLink, RegisterCustomerUserPayload } from './customer-user.interface'
import { IEmailVerificationRequest } from '@upkey-platform/accounts/user-shared'
@Injectable({
  providedIn: 'root'
})
export class CustomerUserService {
  constructor(private http: HttpService) {}

  registerUser(payload: RegisterCustomerUserPayload): Observable<ICustomerUser> {
    return this.http.post<ICustomerUser>(CUSTOMER_USER_API_URL.register, payload)
  }

  verifyEmail(payload: IEmailVerificationRequest): Observable<ICustomerUser> {
    return this.http.post<ICustomerUser>(CUSTOMER_USER_API_URL.verifyEmail, payload)
  }

  verifyLogin(payload: IEmailVerificationRequest): Observable<ICustomerUser> {
    return this.http.post<ICustomerUser>(CUSTOMER_USER_API_URL.verifyLogin, payload)
  }

  getCurrentUser(): Observable<ICustomerUser> {
    return this.http.get<ICustomerUser>(CUSTOMER_USER_API_URL.me)
  }

  login(email: string) {
    return this.http.post(CUSTOMER_USER_API_URL.login, { email })
  }

  logout(): Observable<null> {
    return this.http.get(CUSTOMER_USER_API_URL.logout)
  }

  makePayment(payload: ICreatePayWithPaymentLink): Observable<{ url: string }> {
    return this.http.post<ICustomerUser>(`${CUSTOMER_USER_API_URL.paymentLink}`, payload)
  }

  getPaymentLink(paymentId: string): Observable<{ url: string }> {
    return this.http.get<ICustomerUser>(`${CUSTOMER_USER_API_URL.paymentLink}/${paymentId}`)
  }

  verifyPayment(paymentId: string): Observable<boolean> {
    return this.http.get<ICustomerUser>(`${CUSTOMER_USER_API_URL.verifyPayment}/${paymentId}`)
  }
}
