import { Injector, NgModule } from '@angular/core'
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppRoutingModule } from './app-routing.module'

import { ViewportScroller } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { Router } from '@angular/router'
import { SvgIconsModule } from '@ngneat/svg-icon'
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin'
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin'
import { NgxsModule } from '@ngxs/store'
import { CustomerUserState } from '@upkey-platform/accounts/customer-user/customer-user-data-access'
import { AppInjector, DirectivesModule } from '@upkey-platform/core/core-frontend'
import { environment } from '@upkey-platform/core/core-shared'
import { commonIcons, socialIcons } from '@upkey-platform/icons'
import { ToastNotificationComponent, UiModule } from '@upkey-platform/ui'
import { PixelModule } from 'ngx-pixel'
import { ShareModule, SharerMethod } from 'ngx-sharebuttons'
import { ToastrModule } from 'ngx-toastr'
import { AppComponent } from './app.component'
import { handleScrollOnNavigation } from './core/utils/scroll-restoration'
import { BrowserStateInterceptor } from './interceptors/browser-state/browser-state.interceptor'

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    NgxsModule.forRoot([CustomerUserState], { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
    SvgIconsModule.forRoot({
      icons: [...commonIcons, ...socialIcons]
    }),
    UiModule,
    HttpClientModule,
    PixelModule.forRoot({ enabled: true, pixelId: environment.facebookPixelId }),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 6000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      toastComponent: ToastNotificationComponent
    }),
    DirectivesModule,
    ShareModule.withConfig({
      sharerMethod: SharerMethod.Window
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector, private router: Router, private viewportScroller: ViewportScroller) {
    AppInjector.setInjector(injector)

    // Disable automatic scroll restoration to avoid race conditions
    this.viewportScroller.setHistoryScrollRestoration('manual')
    handleScrollOnNavigation(this.router, this.viewportScroller)
  }
}
