import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpResponseBase
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { makeStateKey, TransferState } from '@angular/platform-browser'
import { EMPTY, Observable, of } from 'rxjs'
import { PROTECTED_STATE_STRING } from '../interceptors.config'

@Injectable({
  providedIn: 'root'
})
export class BrowserStateInterceptor implements HttpInterceptor {
  constructor(private transferState: TransferState) {}

  intercept(req: HttpRequest<HttpResponseBase>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.method === 'GET') {
      const requestURL = new URL(req.url)
      const requestPathKey = `${requestURL.pathname}${requestURL.search}`
      const key = makeStateKey(requestPathKey)
      const storedResponse = this.transferState.get(key, null)

      // Covering the use case of SSR redirects - prevents the request to be executed on the client
      if (key !== '/api/ai/cb/auth/whoami') {
        this.transferState.remove(key)
      }

      if (storedResponse === (PROTECTED_STATE_STRING as unknown)) {
        return EMPTY
      }
      if (storedResponse) {
        const response = new HttpResponse({
          body: storedResponse,
          status: 200
        })
        return of(response)
      }
    }

    return next.handle(req)
  }
}
